<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">踏勘信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所属公司</span>
                      <span class="descriptions-item-content">
                          {{ workOrder
                      && workOrder.createUser
                      && workOrder.createUser.distributor
                      && workOrder.createUser.distributor.name }}
                        </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">踏勘人员</span>
                      <span class="descriptions-item-content">
                          {{ user && user.name  }}
                        </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">房屋类型</span>
                      <span class="descriptions-item-content">
                          {{ roofTypeModel && roofTypeModel.name }}
                        </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">朝向角度</span>
                      <span class="descriptions-item-content">
                          {{ workOrder && workOrder.angle  }}
                        </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网点距离</span>
                      <span class="descriptions-item-content">
                          {{ workOrder && workOrder.distance }}
                        </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">备注</span>
                      <span class="descriptions-item-content">
                          {{ workOrder && workOrder.remarks }}
                        </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="4">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">房屋照片</span>
                      <span v-if="workOrder" class="descriptions-item-content">
                        <image-shows :imgs="viewImages(workOrder.houseImg)"></image-shows>
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">设计信息</div>
            </div>
            <div class="descriptions-view">
              <el-form :model="pageForm"
                       :rules="pageFormRules"
                       ref="formRef"
                       label-position="top"
                       label-width="100px">
                <el-row>
                  <el-col :span="5">
                    <el-form-item prop="number" required label="组件块数" style="width: 100%;">
                      <el-input-number v-model="pageForm.number"
                                       :controls="false"
                                       style="width: 100%;text-align: left;"
                                       controls-position="right"
                                       :max="99999"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <el-form-item prop="size" required label="组件尺寸" style="width: 100%;">
                      <el-input v-model="pageForm.size"
                                placeholder="组件尺寸"
                                :maxlength="10"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <el-form-item prop="power" required label="组件功率(w)" style="width: 100%;">
                      <el-input v-model="pageForm.power"
                                type="number"
                                placeholder="组件功率(w)"
                                :maxlength="10"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <el-form-item prop="npower" required label="逆变器功率(w)">
                      <el-input v-model="pageForm.npower"
                                type="number"
                                placeholder="逆变器功率(w)"
                                :maxlength="10"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item prop="files" required label="设计文件">
                        <el-upload class="upload-demo"
                                   action=""
                                   ref="filesUpload"
                                   list-type="picture"
                                   :multiple="true"
                                   :show-file-list="false"
                                   :limit="5"
                                   :file-list="fileList"
                                   :http-request="handlerUpload" >
                          <el-button size="small" style="border: none;background-color: #E6EBF0;">
                            <custom-show-icon custom-image custom-image-resource="upload"></custom-show-icon>
                            <span style="color: #18191A; margin-left: 10px;position: relative;top: -2.5px;">上传文件</span>
                          </el-button>
                          <div slot="tip" class="el-upload__tip">支持文件格式：CAD, DWG , RAR, ZIP, JPG, PNG, 单个文件不能超过100MB，最多上传5个文件</div>
                        </el-upload>
                        <div class="common-file-list-container"
                             v-for="(iter, index) in fileList" :key="iter.name">
                          <div class="common-file-list-item-left">
                            <div style="display: flex;flex: 1;">
                              <div>
                                <custom-show-icon :filename="iter.name"></custom-show-icon>
                              </div>
                              <div style="margin-left: 14px;flex: 1;font-size: 14px;">{{ iter.name }}</div>
                            </div>
                            <div>
                              <i v-if="0" class="el-icon-download"></i>
                              <i class="el-icon-success" style="color: #19B21E;"></i>
                            </div>
                          </div>
                          <div class="common-file-list-item-right">
                            <i class="el-icon-delete-solid" @click="removeHandler(index)"></i>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="24" style="display: flex;justify-content: flex-end;">
                      <el-button @click="submitClose">取消</el-button>
                      <el-button type="primary" @click="submit">提交</el-button>
                    </el-col>
                  </el-row>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {designCheck, fileUpload, getDesignById} from "@/api/apis";
import helper, {designStatusMapper} from "@/utils/helper";
import {designFileTypeVerifily, isImage, viewImages} from "@/utils/common";
import '@/styles/LPopup.scss'
import CustomShowIcon from "@/components/CustomShowIcon.vue";
import ImageShows from "@/components/ImageShows.vue";

export default {
  name: "Edit",
  components: {ImageShows, CustomShowIcon, PageHeader},
  data(){
    return {
      getData: getDesignById,
      id: '',
      metadata: null,

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},

      //
      fileList: [],
      pageForm: {
        // 设计文件
        files: [],
        // 逆变器功率(w)
        npower: '',
        // 组件块数
        number: '',
        // 组件功率(w)
        power: '',
        // 组件尺寸
        size: '',
      },
      pageFormRules: {
        files: [
          { required: true, message: '请上传设计文件' },
        ],
        npower: [
          { required: true, message: '请填写逆变器功率(w)' }
        ],
        number: [
          { required: true, message: '请填写组件块数' }
        ],
        power: [
          { required: true, message: '请填写组件功率(w)' }
        ],
        size: [
          { required: true, message: '请填写组件尺寸' }
        ],
      },

    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    designStatusMapper,
    viewImages,
    isImage,
    /**
     * 提交
     */
    submit(){
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return false
        }

        // params
        const params = { ...this.pageForm }
        const files = []
        this.fileList.forEach(iter => {
          files.push(iter.value)
        })
        params.files = files.join(',')
        params.designId = this.id

        // submit
        const loading = this.$loading({ fullscreen: true })
        designCheck(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.replace('/admin/design')
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 取消
     */
    submitClose(){
      this.$router.replace('/admin/design')
    },
    /**
     * 自定义文件上传
     */
    handlerUpload(e){
      const res = designFileTypeVerifily(e.file.name)
      if (res) {
        const loading = this.$loading({ fullscreen: true })
        fileUpload(e.file).then(res => {
          const name = ((res || '').split('/') || []).reverse()[0]
          this.fileList.push({
            name: name,
            url: helper.getSourceUri(res),
            value: res
          })
          this.pageForm.files.push(res)
        }).finally(() => {
          loading.close()
        })
      } else {
        this.$message.warning('文件格式不正确')
      }
      this.$refs.filesUpload.uploadFiles = this.$refs.filesUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 移除文件
     */
    removeHandler(index){
      this.fileList.splice(index, 1)
    },
    /**
     * 移除文件
     * @param file
     * @param fileList
     */
    removeHandler_j(file, fileList){
      this.fileList = fileList
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          //
          if (this.designData) {
            const { files, npower, number, power, size } = this.designData
            this.pageForm = { npower, number, power, size, files }
            const filesArr = files.split(',')
            filesArr.forEach(iter => {
              const name = ((iter || '').split('/') || []).reverse()[0]
              this.fileList.push({
                name: name,
                url: helper.getSourceUri(iter),
                value: iter
              })
            })

          }
        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .input-cus .el-input__inner{
  text-align: left;
}
/deep/ .el-input-number .el-input__inner{
  text-align: left;
}
</style>